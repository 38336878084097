<template>
  <v-dialog
    v-model="showModal"
    width="700"
  >
    <v-card
      outlined
      color="#ECEFF1"
    >
      <br>
      <h2 class="title-style">
        <v-icon
          class="icon-style"
          left
        >
          mdi-pencil
        </v-icon>Update Budget
      </h2>
      <br>
      <v-form
        ref="form"
        v-model="formValid"
      >
        <v-container fluid>
          <v-card-text>
            <v-row>
              <v-col
                cols="12"
              >
                <v-text-field
                  v-model="budgetName"
                  label="Budget Name*"
                  class="field-style"
                  dense
                  outlined
                  :rules="[(v) => !!v || 'This field is required']"
                />
              </v-col>
              <v-col
                cols="12"
              >
                <v-textarea
                  v-model="description"
                  class="field-style"
                  filled
                  auto-grow
                  rows="2"
                  label="Descripion"
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-dialog
                  ref="startDialog"
                  v-model="startDateDialog"
                  :return-value.sync="startDate"
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <h6 class="field-style">
                      <span class="planned-black">Planned Start Date : </span><span
                        v-if="plannedStartDate !== null"
                        class="planned-orange"
                      >{{ plannedStartDate }}</span><span
                        v-else
                        class="planned-orange"
                      >
                        N/A
                      </span>
                      <v-icon
                        class="edit-icon-style"
                        color="#37474F"
                        small
                        @click="plannedStartDateDialog = true"
                      >
                        mdi-pencil
                      </v-icon>
                    </h6>
                    <v-text-field
                      v-model="startDate"
                      label="Actual Start Date"
                      prepend-inner-icon="mdi-calendar"
                      hint="Set actual start date of this item"
                      persistant-hint
                      readonly
                      outlined
                      dense
                      v-bind="attrs"
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="startDate"
                    scrollable
                    color="#37474F"
                    @input="$refs.startDialog.save(startDate)"
                  >
                    <v-spacer />
                    <v-btn
                      text
                      color="primary"
                      @click="startDateDialog = false"
                    >
                      Cancel
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
                <v-dialog
                  v-model="plannedStartDateDialog"
                  width="290px"
                >
                  <v-date-picker
                    v-model="selectedPlannedStartDate"
                    scrollable
                    color="#37474F"
                    @input="savePlannedStartDate()"
                  >
                    <v-spacer />
                    <v-btn
                      text
                      color="primary"
                      @click="closePlannedStartDate()"
                    >
                      Cancel
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-dialog
                  ref="endDialog"
                  v-model="endDateDialog"
                  :return-value.sync="endDate"
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <h6 class="field-style">
                      <span class="planned-black">Planned End Date : </span><span
                        v-if="plannedEndDate !== null"
                        class="planned-orange"
                      >{{ plannedEndDate }}
                      </span>
                      <span
                        v-else
                        class="planned-orange"
                      >
                        N/A
                      </span>
                      <v-icon
                        class="edit-icon-style"
                        color="#37474F"
                        small
                        @click="plannedEndDateDialog = true"
                      >
                        mdi-pencil
                      </v-icon>
                    </h6>
                    <v-text-field
                      v-model="endDate"
                      label="Actual End Date"
                      prepend-inner-icon="mdi-calendar"
                      hint="Set actual end date of this item"
                      persistant-hint
                      readonly
                      outlined
                      dense
                      v-bind="attrs"
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="endDate"
                    scrollable
                    color="#37474F"
                    :min="minEndDate"
                    @input="$refs.endDialog.save(endDate)"
                  >
                    <v-spacer />
                    <v-btn
                      text
                      color="primary"
                      @click="endDateDialog = false"
                    >
                      Cancel
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
                <v-dialog
                  v-model="plannedEndDateDialog"
                  width="290px"
                >
                  <v-date-picker
                    v-model="selectedPlannedEndDate"
                    scrollable
                    :min="minPlannedEndDate"
                    color="#37474F"
                    @input="savePlannedEndDate()"
                  >
                    <v-spacer />
                    <v-btn
                      text
                      color="primary"
                      @click="closePlannedEndDate()"
                    >
                      Cancel
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>
              <v-col
                cols="12"
                md="12"
              >
                <v-select
                  v-model="budgetStatus"
                  class="field-style"
                  :items="statusList"
                  label="Budget Status*"
                  :rules="[(v) => !!v || 'This field is required']"
                  single-line
                  item-value="id"
                  item-text="name"
                  outlined
                  dense
                />
              </v-col>
              <v-col
                cols="12"
                md="12"
              >
                <v-checkbox
                  v-model="highRiskWork"
                  label="HRW (High Risk Work)"
                  hint="Work involves risk"
                  persistent-hint
                  class="hrw-checkbox checkbox-style"
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="field-style">
            <v-btn
              class="mt-2"
              color="red darken-3"
              @click="showModal = false"
            >
              cancel
            </v-btn>
            <v-spacer />
            <v-btn
              class="mt-2"
              color="#FF3700"
              @click="updateBudgetDetails()"
            >
              Update
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-form>
      <centre-spinner
        :loading="loading"
      />
    </v-card>
  </v-dialog>
</template>

<script>
import Constants from 'src/constants';
import spinner from 'src/views/dashboard/component/SpinnerCentre';
import moment from 'moment';

export default {
  name: 'EditBudgetDetails',
  components: {
      'centre-spinner': spinner,
    },
  props: {
    budgetId: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      showModal: true,
      formValid: false,
      loading: false,
      budgetName: '',
      description: '',
      startDate: null,
      endDate: null,
      budgetStatus: '',
      statusList: [
        {
          name: 'Active',
          id: 'active',
        },
        {
          name: 'Pending',
          id: 'pending',
        },
        {
          name: 'Completed',
          id: 'completed',
        },
      ],
      highRiskWork: null,
      startDateDialog: false,
      endDateDialog: false,
      minStartDate: moment().format('YYYY-MM-DD'),
      plannedStartDateDialog: false,
      plannedStartDate: null,
      selectedPlannedStartDate: null,
      plannedEndDateDialog: false,
      plannedEndDate: null,
      selectedPlannedEndDate: null,
    };
  },
  computed: {
    budgetDetails () {
      return this.$store.getters['projects/getBudgetDetails'];
    },
    minEndDate () {
      return moment(this.startDate).format('YYYY-MM-DD');
    },
    minPlannedEndDate () {
      return moment(this.plannedStartDate).format('YYYY-MM-DD');
    },
  },
  watch: {
    showModal: function (newval, oldval) {
      if (newval === false) {
        this.$emit('closed');
      }
    },
  },
  async mounted () {
    await this.getBudjectDetails();
  },
  methods: {
    async getBudjectDetails () {
      this.loading = true;
      await this.$store.dispatch('projects/fetchBudgetDetails', {
        budgetId: this.budgetId,
      }).then(response => {
        this.budgetName = this.budgetDetails.name;
        this.description = this.budgetDetails.description;
        this.startDate = this.budgetDetails.actual_start_date;
        this.endDate = this.budgetDetails.actual_end_date;
        this.budgetStatus = this.budgetDetails.status;
        this.highRiskWork = this.budgetDetails.high_risk_work;
        this.startDate = this.budgetDetails.actual_start_date;
        this.endDate = this.budgetDetails.actual_end_date;
        this.plannedEndDate = this.budgetDetails.planned_end_date;
        this.selectedPlannedEndDate = this.budgetDetails.planned_end_date;
        this.plannedStartDate = this.budgetDetails.planned_start_date;
        this.selectedPlannedStartDate = this.budgetDetails.planned_start_date;
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      });
    },
    updateBudgetDetails () {
      if (this.$refs.form.validate() === false) {
        this.$store.dispatch('alert/onAlert', {
            message: 'Please fill the required fields before proceeding.',
            type: Constants.ALERT_TYPE_ERROR,
          }, { root: true });
          return;
      }
      this.loading = true;
      this.$store.dispatch('projects/updateBudgetDetails', {
        budgetId: this.budgetId,
        updateDetails: {
          name: this.budgetName,
          description: this.description,
          actual_end_date: this.endDate,
          actual_start_date: this.startDate,
          status: this.budgetStatus,
          high_risk_work: this.highRiskWork,
          planned_start_date: this.plannedStartDate,
          planned_end_date: this.plannedEndDate,
        },
      }).then(response => {
        this.$store.dispatch('alert/onAlert', {
          message: 'Budget details updated successfully.',
          type: Constants.ALERT_TYPE_SUCCESS,
        });
        this.$emit('budget-details-updated');
        this.loading = false;
        this.showModal = false;
      }).catch(() => {
        this.loading = false;
      });
    },
    savePlannedStartDate () {
      this.plannedStartDate = this.selectedPlannedStartDate;
      this.plannedStartDateDialog = false;
    },
    closePlannedStartDate () {
      this.selectedPlannedStartDate = this.plannedStartDate;
      this.plannedStartDateDialog = false;
    },
    savePlannedEndDate () {
      this.plannedEndDate = this.selectedPlannedEndDate;
      this.plannedEndDateDialog = false;
    },
    closePlannedEndDate () {
      this.selectedPlannedEndDate = this.plannedEndDate;
      this.plannedEndDateDialog = false;
    },
  },
};
</script>

<style scoped>
.title-style {
  color: #37474F;
  margin-left: 30px;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
.planned-black{
  color: #546E7A;
}
.planned-orange{
  color: #FF3700;
}
.field-style{
  margin-top: -10px;
}
.checkbox-style{
  margin-top: -30px;
}
.edit-icon-style {
  margin-top: -5px;
  margin-left: 2px;
}
</style>
