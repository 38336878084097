<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <br>
    <h2 class="title-style">
      <v-icon
        class="icon-style"
        left
      >
        mdi-chart-box-multiple-outline
      </v-icon> Performance Tracking
    </h2>
    <v-btn
      v-if="showSyncButton"
      text
      outlined
      class="sync-btn"
      @click="showSyncAlert = true"
    >
      <v-icon
        color="#37474F"
        size="30"
        left
      >
        mdi-cloud-sync-outline
      </v-icon>
      <span class="sync-btn-txt"> Sync Autodesk </span>
    </v-btn>
    <v-card
      shaped
      color="#CFD8DC"
    >
      <br>
      <v-col class="text-right">
        <v-btn
          color="#FF3700"
          @click="showAddDialog = true"
        >
          Add New
        </v-btn>
      </v-col>
      <hr>
      <br>
      <v-card-title>
        <v-row>
          <v-col
            cols="12"
            lg="4"
            md="5"
            sm="12"
          >
            <v-text-field
              v-model="search"
              label="Search"
              append-icon="mdi-magnify"
              outlined
              dense
            />
          </v-col>
        </v-row>
      </v-card-title>
      <p
        v-if="partnerCheck()"
        class="info-text"
      >
        *Indicates from partner account
      </p>
      <v-data-table
        :headers="headers"
        :search="search"
        :items="performanceTrackingItems"
        class="elevation-1"
        disable-sort
        mobile-breakpoint="100"
      >
        <template v-slot:item="{ item }">
          <tr>
            <td
              class="more-link"
              @click="showTrackingItemInstances(item.id)"
            >
              {{ item.budget.name }}<span
                v-if="item.database === false"
                class="partner-project"
              >*</span>
            </td>
            <td>
              {{ item.budget.quantity }}  <span class="hrs-style">Hrs</span>
            </td>
            <td><span class="currency-style">{{ projectDetails.currency }}</span> {{ item.budget.unit_cost }}</td>
            <td><span class="currency-style">{{ projectDetails.currency }}</span> {{ item.budget.original_amount }}</td>
            <td>
              <font
                v-if="item.budget.status !== null"
                :color="getStatusColor(item.budget.status)"
                class="font-my-style"
              >
                {{ item.budget.status | statusFormat }}
              </font>
              <font v-else>
                N/A
              </font>
            </td>
            <td>
              <v-btn
                class="mt-n2"
                elevation="1"
                fab
                x-small
                icon
                color="#37474F"
                @click="showTrackingItemInstances(item.id)"
              >
                <v-icon>mdi-eye</v-icon>
              </v-btn>
            </td>
            <td>
              <v-btn
                class="mt-n2"
                elevation="1"
                fab
                x-small
                :disabled="item.database === false"
                icon
                color="#C62828"
                @click="deleteTrackingItem(item.id)"
              >
                <v-icon>mdi-trash-can</v-icon>
              </v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>
      <br>
    </v-card>
    <centre-spinner
      :loading="loading"
    />
    <add-performance-tracking-items
      v-if="showAddDialog"
      @closed="showAddDialog = false"
      @success="getPerformanceTrackingItems"
    />
    <partner-auth-check
      ref="partnerAuth"
      @partner-authorized="getTrackingItemsFromPartner"
    />
    <overlay-alert
      :show-progress="showProgress"
      :process-alert="processAlert"
    />
    <delete-tracking-item
      v-if="showDeleteDialog"
      :title="'Delete this tracking item ?'"
      :message="'Action will permanently remove this performance tracking item from the list, Do you want to continue ?'"
      @closed="showDeleteDialog = false"
      @confirmed="deleteConfirmed()"
    />
    <performance-tracking-sync-alert
      v-if="showSyncAlert"
      :title="'Sync Performance Tracking'"
      :message="'Project budgets should sync before performance tracking, did you sync all project budgets to EzyBuild ?'"
      :cancel-button="'No'"
      :confirm-button="'Yes'"
      @closed="routeToProjectBudgets"
      @confirmed="syncTrackingItemsFromPartner"
    />
  </v-container>
</template>

<script>
  import spinner from 'src/views/dashboard/component/SpinnerCentre';
  import AddPerformanceTrackingItems from './AddPerformanceTrackingItems';
  import global from 'src/mixins/global';
  import PartnerAuthCheck from 'src/views/dashboard/partnerauth/PartnerAuthCheck';
  import OverlayAlert from 'src/views/dashboard/component/OverlayAlert';
  import DeleteDialog from 'src/views/dashboard/component/DeleteDialog';
  import Constants from 'src/constants';

  export default {
    name: 'PerformanceTrackingItems',
    components: {
      'centre-spinner': spinner,
      'add-performance-tracking-items': AddPerformanceTrackingItems,
      'partner-auth-check': PartnerAuthCheck,
      'overlay-alert': OverlayAlert,
      'delete-tracking-item': DeleteDialog,
      'performance-tracking-sync-alert': DeleteDialog,
    },
    filters: {
      statusFormat (status) {
        if (status === 'active') {
          return 'Active*';
        } else if (status === 'pending') {
          return 'Pending';
        } else if (status === 'completed') {
          return 'Completed*';
        } else {
          return status;
        }
      },
    },
    mixins: [global],
    data () {
      return {
        loading: false,
        headers: [
          { text: 'Budget Name', align: 'start', value: 'budget.name' },
          { text: 'Quantity', value: 'quantity' },
          { text: 'Unit Cost', value: 'unit_cost' },
          { text: 'Original Amount', value: 'original_amount' },
          { text: 'Status', value: 'status' },
          { text: 'More', value: 'more' },
          { text: 'Delete', value: 'delete' },
        ],
        search: '',
        showAddDialog: false,
        showProgress: false,
        processAlert: '',
        showDeleteDialog: false,
        trackingItemId: '',
        showSyncAlert: false,
      };
    },
    computed: {
      performanceTrackingItems () {
        return this.$store.getters['projects/getPerformanceTrackingItems'];
      },
      projectDetails () {
        return this.$store.getters['projects/getProjectDetails'];
      },
      showSyncButton () {
        if (this.projectDetails.database === false) {
          return true;
        }
        return false;
      },
    },
    async mounted () {
      this.loading = true;
      await this.getPerformanceTrackingItems();
      this.loading = false;
    },
    methods: {
      async getPerformanceTrackingItems () {
        this.loading = true;
          await this.$store.dispatch('projects/fetchPerformanceTrackingItems', {
            projectId: this.$route.params.projectId,
          }).catch(() => {
            this.loading = false;
          });
          this.loading = false;
      },
      getStatusColor (status) {
        if (status === 'completed') return '#FF3700';
        else if (status === 'active') {
          return '#2E7D32';
        } else if (status === 'pending') {
          return '#C62828';
        } else {
          return '#37474F';
        }
      },
      syncTrackingItemsFromPartner () {
        this.showSyncAlert = false;
        this.$refs.partnerAuth.isPartnerAuthorized();
      },
      async getTrackingItemsFromPartner () {
        this.showProgress = true;
        this.processAlert = 'Syncing...';
        await this.$store.dispatch('partners/fetchTrackingItemsFromPartner', {
          projectId: this.$route.params.projectId,
        }).then(response => {
          this.showProgress = false;
          this.getPerformanceTrackingItems();
        }).catch(() => {
          this.showProgress = false;
        });
      },
      showTrackingItemInstances (trackingItemId) {
        const projectId = this.$route.params.projectId;
        this.$router.push(`/projects/${projectId}/performance-tracking-items/${trackingItemId}/instances`);
      },
      deleteTrackingItem (id) {
        this.trackingItemId = id;
        this.showDeleteDialog = true;
      },
      async deleteConfirmed () {
        this.loading = true;
        this.showDeleteDialog = false;
        await this.$store.dispatch('projects/deleteTrackingItem', {
          trackingItemId: this.trackingItemId,
        }).then(response => {
          this.$store.dispatch('alert/onAlert', {
            message: 'Performance tracking item deleted successfully.',
            type: Constants.ALERT_TYPE_SUCCESS,
          });
          this.loading = false;
          this.getPerformanceTrackingItems();
        }).catch(() => {
          this.loading = false;
        });
      },
      routeToProjectBudgets () {
        this.showSyncAlert = false;
        this.$emit('show-budgets');
      },
    },
  };
</script>

<style scoped>
.v-data-table::v-deep th {
  font-size: 12px !important;
  color: #37474F !important;
  font-weight: bold !important;
}
.v-data-table::v-deep td {
  font-size: 13px !important;
  color: #37474F !important;
}
.theme--light.v-data-table tbody tr:nth-of-type(even) {
  background-color: #CFD8DC
}
.v-data-table { background-color: #ECEFF1; }
.title-style {
  color: #37474F;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
.font-my-style {
  text-transform: capitalize;
  font-weight: bold;
}
.currency-style{
  color: #FF3700;
  font-family: 'Times New Roman', Times, serif;
  font-size: 11px;
}
.hrs-style {
  color: #37474F;
  font-family: 'Times New Roman', Times, serif;
  font-size: 11px;
  text-transform: capitalize;
}
.sync-btn-txt {
  color: #FF3700;
  font-weight: bold;
  font-family: 'Times New Roman', Times, serif;
  text-transform: capitalize;
  margin-top: 3px;
  margin-left: 5px;
  font-size: 15px;
}
.sync-btn {
  margin-left: 8px;
  margin-top: 10px;
}
.partner-project {
  color: #FF3700;
  font-weight: bold;
}
.info-text {
  font-family: 'Times New Roman', Times, serif;
  font-weight: bold;
  font-style: italic;
  color: #37474F;
  margin-left: 15px;
  margin-top: -20px;
}
.more-link{
  cursor: pointer;
}
</style>
